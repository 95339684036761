import {request} from "../request/request";

const preParticipant = {
  findAll: (params) => request.read('/pre-participant', params, true),
  findOne: (params) => request.read('/pre-participant/find', params, true),
  getCount: (params) => request.read('/pre-participant/count', params, true),
  getEducationCertificatesArchive: () => request.read('/pre-participant/education-certificates-archive', null, true),
  getHistory: (params) => request.read('/pre-participant/history', params, true),
  getMyHistory: (params) => request.read('/pre-participant/my-history', params, true),
  getOne: (preParticipantId) => request.read(`/pre-participant/${preParticipantId}`, null, true),
  getPassportsArchive: () => request.read('/pre-participant/passports-archive', null, true),
  postEducationCertificatesArchive: () => request.create('/pre-participant/education-certificates-archive-create', null, true),
  postPassportsArchive: () => request.create('/pre-participant/passports-archive-create', null, true),
};

export {preParticipant};