import React from "react";
import TextHelper from "../../utils/TextHelper";

export default function TextBlock(props) {
  // Текст в виде строки или массива строк.
  // Если одна строка, то она делится на абзацы по символу переноса строки.
  const {text, className = '', tagName = 'p'} = props;
  const lines = typeof text === 'string' ? TextHelper.splitLines(text) : text;

  return (
    <>
      {lines.map((line, index) => React.createElement(tagName, {key: index, className: className}, line))}
    </>
  );
}
