import {request} from "../request/request";

const location = {
  findAll: (params) => request.read('/location/find-all', params, true),
  findOlympiadStepDate: (locationId, params) => request.read(`/location/${locationId}/pivotal-date`, params, true),
  getAddress: (locationId) => request.read(`/location/${locationId}/address`, null, true),
  getListForEnrol: (params) => request.read('/location/list-for-enrol', params, true),
  getOne: (locationId) => request.read(`/location/${locationId}`, null, true),
  getSearchList: (params) => request.read('/location/search-list', params, true),
  getStatistics: (locationId, stepId) => request.read(`/location/${locationId}/statistics/${stepId}`, null, true),
  getTimezoneSettings: (params) => request.read('location/timezone-settings', params, true),
  postOne: (data) => request.create('/location', data, true),
  putOne: (locationId, data) => request.update(`/location/${locationId}`, data, true),
};

export {location};