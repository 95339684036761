import {request} from "../request/request";

const locationRequest = {
  deleteOne: (locationRequestId) => request.remove(`/location-request/${locationRequestId}`, null, true),
  findAll: (params) => request.read('/location-request/find-all', params, true),
  findOne: (params) => request.read('/location-request/find-one', params, true),
  getAgreement: (locationRequestId) => request.read(`/location-request/${locationRequestId}/agreement`, null, true),
  getAgreementTemplate: (locationRequestId) => request.read(`/location-request/${locationRequestId}/agreement-template`, null, true),
  getCount: (params) => request.read('/location-request/count', params, true),
  getModeratedList: (params) => request.read('/location-request/moderated-list', params, true),
  getReportFile: (params) => request.read('/location-request/report-file', params, true),
  putAgreement: (locationRequestId, data) => request.update(`/location-request/${locationRequestId}/agreement`, data, true),
  putApprove: (locationRequestId, data) => request.update(`/location-request/${locationRequestId}/approve`, data, true),
  putDeny: (locationRequestId, data) => request.update(`/location-request/${locationRequestId}/deny`, data, true),
  putLocationSteps: (locationRequestId, data) => request.update(`/location-request/${locationRequestId}/location-step`, data, true),
  putRevoke: (locationRequestId) => request.update(`/location-request/${locationRequestId}/revoke`, null, true),
};

export {locationRequest};