import "./header.css";
import {Row} from "react-bootstrap";
import {MainMenu} from "../../features/menu/main";
import {useSelector} from "react-redux";

export default function HeaderContent() {
  const isGuest = useSelector(state => state.user.isGuest);

  let cssClasses = [
    'header',
    'content-header',
  ];

  if (!isGuest) {
    cssClasses.push('auth-header');
  }

  return (
    <Row className={cssClasses.join(' ')}>
      <MainMenu/>
    </Row>
  );
}
