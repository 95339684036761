import {request} from "../request/request";

const finalStepRequest = {
  getAll: () => request.read('/final-step-request', null, true),
  getOne: (requestId) => request.read(`/final-step-request/${requestId}`, null, true),
  getRequested: (params) => request.read('/final-step-request/requested', params, true),
  postOne: (data) => request.create('/final-step-request', data, true),
  putApprove: (requestId) => request.update(`/final-step-request/${requestId}/approve`, null, true),
  putDeny: (requestId, data) => request.update(`/final-step-request/${requestId}/deny`, data, true),
  putReopen: (requestId, data) => request.update(`/final-step-request/${requestId}/reopen`, data, true),
};

export {finalStepRequest};