import {createSlice} from "@reduxjs/toolkit";
import {api, isAuthenticated, signOutLocal, signOutLocalLegacy} from "../../../services/backend";

const slice = createSlice({
  name: 'user',
  initialState: {
    loaded: false,
    isGuest: true,
    profile: null,
  },
  reducers: {
    setProfile: (state, action) => {
      state.loaded = true;
      state.isGuest = null === action.payload;
      state.profile = action.payload;
    },
    resetProfile: (state) => {
      state.loaded = true;
      state.isGuest = true;
      state.profile = null;
      signOutLocal();
      signOutLocalLegacy();
    },
  },
});

export const {setProfile, resetProfile} = slice.actions;

export const syncProfile = () => async (dispatch) => {
  if (!isAuthenticated()) {
    dispatch(setProfile(null));
    return;
  }

  try {
    const response = await api.me.getOne();
    dispatch(setProfile(response));
  } catch (error) {
    dispatch(resetProfile());
  }
};

export default slice.reducer;
