import iconBlue from "../icon/icon-delete.svg";
import iconWhite from "../icon/icon-delete-white.svg";
import ControlButton from "./ControlButton";

export default function DeleteButton(props) {
  const {variant = 'primary', link} = props;
  const icon = 'secondary' === variant ? iconWhite : iconBlue;

  return <ControlButton link={link} icon={icon} alt={'Удалить'}/>;
}
