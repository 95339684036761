import {api} from "../api";
import {token} from "../token/token";

const signIn = async (data) => {
  try {
    const response = await api.oauth.signIn.create(data);
    token.saveToken(response);
  } catch (error) {
    throw error;
  }
};

export {signIn};