import SolutionsItem from "./items/SolutionsItem";
import AppealsItem from "./items/AppealsItem";
import AnnouncementsItem from "./items/AnnouncementsItem";
import MessagesItem from "./items/MessagesItem";
import ProfileItem from "./items/ProfileItem";

export default function JuryItems() {
  return (
    <>
      <SolutionsItem/>
      <AppealsItem/>
      <AnnouncementsItem/>
      <MessagesItem/>
      <ProfileItem/>
    </>
  );
}