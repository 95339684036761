import {request} from "../request/request";

const solution = {
  deleteScore: (solutionId) => request.remove(`/solution/${solutionId}/score`, null, true),
  findAll: (params) => request.read('/solution/find-all', params, true),
  findOne: (params) => request.read('/solution/find-one', params, true),
  getCount: (params) => request.read('/solution/count', params, true),
  getMy: (params) => request.read('/solution/my', params, true),
  getOne: (solutionId) => request.read(`/solution/${solutionId}`, null, true),
  getReview: (params) => request.read('/solution/review', params, true),
  postPreliminaryResultUpload: (data) => request.create('/solution/preliminary-result-upload', data, true),
  postSolutionUpload: (data) => request.create('/solution/solution-upload', data, true),
  putInspect: (solutionId, data) => request.update(`/solution/${solutionId}/inspect`, data, true),
  putInspector: (solutionId, data) => request.update(`/solution/${solutionId}/inspector`, data, true),
  putPreScore: (participantId, data) => request.update(`/solution/${participantId}/preliminary-result`, data, true),
  putScore: (participantId, data) => request.update(`/solution/${participantId}/final-result`, data, true),
  putRefuse: (solutionId) => request.update(`/solution/${solutionId}/refuse`, null, true),
  putTakeOnCheck: (solutionId) => request.update(`/solution/${solutionId}/take-on-check`, null, true),
};

export {solution};