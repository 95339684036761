import {createSlice} from "@reduxjs/toolkit";
import randomString from "randomstring";

const slice = createSlice({
  name: 'flashMessages',
  initialState: {
    messages: [],
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    closeFlashMessage: (state, action) => {
      const deletingIndex = state.messages.findIndex(message => message.key === action.payload);

      if (-1 === deletingIndex) {
        return;
      }

      state.messages.splice(deletingIndex, 1);
    },
  },
});

export const {addMessage, closeFlashMessage} = slice.actions;

export const addFlashMessage = (error) => async (dispatch) => {
  const key = randomString.generate({length: 8, charset: 'alphabetic', capitalization: 'lowercase'});

  error.key = key;
  dispatch(addMessage(error));

  setTimeout(() => {
    dispatch(closeFlashMessage(key));
  },10000); // самоуничтожение сообщения через 10 секунд
};

export default slice.reducer;