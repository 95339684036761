import {useLocation} from "react-router-dom";
import Item from "./Item";
import {itemIsActive} from "../../utils/itemIsActive";
import useAnnouncementsCount from "../../hooks/useAnnouncementsCount";
import {useEffect} from "react";

export default function AnnouncementsItem() {
  const url = useLocation();
  const {count, reload} = useAnnouncementsCount();

  useEffect(() => {
    reload();
  }, [url]);

  return (
    <Item
      link={'/announcements'}
      label={'Объявления'}
      count={count}
      active={itemIsActive(url.pathname, ['/announcements', '/announcement'])}
    />
  );
}