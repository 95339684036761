import {request} from "../request/request";

const period = {
  findAll: (params) => request.read('/period', params, true),
  findOne: (params) => request.read('/period/find', params, true),
  getExists: () => request.read('/period/exist', null, true),
  getOne: (periodId) => request.read(`/period/${periodId}`, null, true),
  postOne: () => request.create('/period', null, true),
};

export {period};