import {Col, Row} from "react-bootstrap";
import Contacts from "../contacts/Contacts";
import Copyright from "../copyright/Copyright";
import Logo from "../logo/Logo";

export default function FooterAuth() {
  return (
    <Row className="footer footer-auth">
      <Col xs={12} md={4}>
        <Logo />
      </Col>
      <Col xs={12} md={8}>
        <Contacts />
        <Copyright />
      </Col>
    </Row>
  );
}
