import {request} from "../request/request";

const locationStep = {
  deleteAppealDate: (locationStepId) => request.remove(`/location-step/${locationStepId}/appeal-date`, null, true),
  deleteInstruction: (locationStepId) => request.remove(`/location-step/${locationStepId}/instruction`, null, true),
  findAll: (params) => request.read('/location-step/find-all', params, true),
  findOne: (params) => request.read('/location-step/find-one', params, true),
  putOne: (locationStepId, data) => request.update(`/location-step/${locationStepId}`, data, true),
  putVariant: (locationStepId, data) => request.update(`/location-step/${locationStepId}/variant`, data, true),
};

export {locationStep};