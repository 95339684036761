import {request} from "../request/request";

const pivotalDate = {
  deleteOne: (id) => request.remove(`/pivotal-date/${id}`, null, true),
  findAll: (params) => request.read('/pivotal-date', params, true),
  findOne: (params) => request.read('/pivotal-date/find', params, true),
  /**
   * TODO: реализовать и заменить на ApiRequest.report.getAttachment()
   * @param id
   * @param params
   * @returns {Promise<boolean|*|undefined>}
   * @deprecated
   */
  getAttachment: (id, params) => request.read(`/pivotal-date/${id}/attachment`, params, true),
  getOlympiad: (id) => request.read(`/pivotal-date/${id}/olympiad`, null, true),
  getOlympiadStep: (id) => request.read(`/pivotal-date/${id}/olympiad-step`, null, true),
  getOne: (id) => request.read(`/pivotal-date/${id}`, null, true),
  putOne: (id, data) => request.update(`/pivotal-date/${id}`, data, true),
  postParticipationProtocol: (id, data) => request.create(`/pivotal-date/${id}/participation-protocol`, data, true),
  postParticipantWork: (id, data) => request.create(`/pivotal-date/${id}/participant-work`, data, true),
};

export {pivotalDate};