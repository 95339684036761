import {request} from "../request/request";

const report = {
  deleteTimezone: (reportId) => request.remove(`/report/${reportId}/timezone`, null, true),
  findAll: (params) => request.read('/report', params, true),
  findOne: (params) => request.read('/report/find', params, true),
  getOne: (reportId) => request.read(`/report/${reportId}`, null, true),
  postOne: (data) => request.create('/report', data, true),
  putOne: (reportId, data) => request.update(`/report/${reportId}`, data, true),
};

export {report};