import {Col, Row} from "react-bootstrap";
import Contacts from "../contacts/Contacts";
import Copyright from "../copyright/Copyright";
import Line from "../line/Line";

export default function FooterGuest() {
  return (
    <Row className="footer footer-start">
      <Col xs="auto">
        <Line />
        <Contacts />
        <Copyright />
      </Col>
    </Row>
  );
}
