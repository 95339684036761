import {announcement} from "./announcement";
import {appeal} from "./appeal";
import {city} from "./city";
import {discipline} from "./discipline";
import {documentType} from "./document-type";
import {education} from "./education";
import {file} from "./file";
import {finalStepRequest} from "./final-step-request";
import {invitation} from "./invitation";
import {location} from "./location";
import {locationRequest} from "./location-request";
import {locationStep} from "./location-step";
import {material} from "./material";
import {me} from "./me";
import {message} from "./message";
import {oauth} from "./oauth";
import {olympiad} from "./olympiad";
import {olympiadStep} from "./olympiad-step";
import {participant} from "./participant";
import {passport} from "./passport";
import {password} from "./password";
import {period} from "./period";
import {personalData} from "./personal-data";
import {pivotalDate} from "./pivotal-date";
import {preParticipant} from "./pre-participant";
import {region} from "./region";
import {report} from "./report";
import {scoreCriterion} from "./score-criterion";
import {settingOlympiad} from "./setting-olympiad";
import {signUp} from "./sign-up";
import {solution} from "./solution";
import {studyClass} from "./study-class";
import {user} from "./user";
import {variant} from "./variant";
import {verifyEmail} from "./verify-email";

const api = {
  announcement,
  appeal,
  city,
  discipline,
  documentType,
  education,
  file,
  finalStepRequest,
  invitation,
  location,
  locationRequest,
  locationStep,
  material,
  me,
  message,
  oauth,
  olympiad,
  olympiadStep,
  participant,
  passport,
  password,
  period,
  personalData,
  pivotalDate,
  preParticipant,
  region,
  report,
  scoreCriterion,
  settingOlympiad,
  signUp,
  solution,
  studyClass,
  user,
  variant,
  verifyEmail,
};

export {api};