import ArchiveItem from "./items/ArchiveItem";
import SectionsItem from "./items/SectionsItem";
import LocationsItem from "./items/LocationsItem";
import ParticipantsItem from "./items/ParticipantsItem";
import AnnouncementsItem from "./items/AnnouncementsItem";
import MessagesItem from "./items/MessagesItem";
import ProfileItem from "./items/ProfileItem";

export default function AdminItems() {
  return (
    <>
      <ArchiveItem/>
      <SectionsItem/>
      <LocationsItem/>
      <ParticipantsItem/>
      <AnnouncementsItem/>
      <MessagesItem/>
      <ProfileItem/>
    </>
  );
}