import {useLocation} from "react-router-dom";
import Item from "./Item";
import {itemIsActive} from "../../utils/itemIsActive";

export default function LocationsItem(props) {
  const {label = 'Площадки', activeFor = ['/locations', '/location']} = props;
  const url = useLocation();

  return (
    <Item
      link={'/locations'}
      label={label}
      active={itemIsActive(url.pathname, activeFor)}
    />
  );
}