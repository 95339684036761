import {request} from "../request/request";

const announcement = {
  getAll: (params) => request.read('/announcement', params, true),
  getCountNew: () => request.read('/announcement/count-of-new', null, true),
  getLast: () => request.read('/announcement/last', null, true),
  getOne: (announcementId) => request.read(`/announcement/${announcementId}`, null, true),
  putOne: (announcementId, data) => request.update(`/announcement/${announcementId}`, data, true),
  postOne: (data) => request.create('/announcement', data, true),
  deleteOne: (announcementId) => request.remove(`/announcement/${announcementId}`, null, true),
};

export {announcement};