import {request} from "../request/request";

const appeal = {
  deleteDecision: (appealId) => request.remove(`/appeal/${appealId}/decision`, null, true),
  findAll: (params) => request.read('/appeal/find-all', params, true),
  findOne: (params) => request.read('/appeal/find-one', params, true),
  getCount: (params) => request.read('/appeal/count', params, true),
  getOne: (appealId) => request.read(`/appeal/${appealId}`, null, true),
  getReportFile: (params) => request.read('/appeal/report-file', params, true),
  getReview: (params) => request.read('/appeal/review', params, true),
  postOne: (data) => request.create('/appeal', data, true),
  putInspect: (appealId, data) => request.update(`/appeal/${appealId}/inspect`, data, true),
  putInspector: (appealId, data) => request.update(`/appeal/${appealId}/inspector`, data, true),
  putRefuse: (appealId) => request.update(`/appeal/${appealId}/refuse`, null, true),
  putTakeOnCheck: (appealId) => request.update(`/appeal/${appealId}/take-on-check`, null, true),
};

export {appeal};