import {request} from "../request/request";

const olympiad = {
  deleteOne: (olympiadId) => request.remove(`/olympiad/${olympiadId}`, null, true),
  findAll: (params) => request.read('/olympiad/find-all', params, true),
  findOne: (params) => request.read('/olympiad/find-one', params, true),
  getActive: () => request.read('/olympiad/active', null, true),
  getFinalStepRequestAvailable: () => request.read('/olympiad/final-step-request-available', null, true),
  getLocationRegistrationOpened: () => request.read('/olympiad/location-registration-opened', null, true),
  getOne: (olympiadId) => request.read(`/olympiad/${olympiadId}`, null, true),
  getByParticipant: (params) => request.read('/olympiad/by-participant', params, true),
  getParticipantRegistrationOpened: () => request.read('/olympiad/participant-registration-opened', null, true),
  postOne: (data) => request.create('/olympiad', data, true),
  putOne: (olympiadId, data) => request.update(`/olympiad/${olympiadId}`, data, true),
};

export {olympiad};