import {request} from "../request/request";

const olympiadStep = {
  deleteInstruction: (id) => request.remove(`/olympiad-step/${id}/instruction`, null, true),
  deleteScoreCriterion: (id) => request.remove(`/olympiad-step/${id}/score-criterion`, null, true),
  findAll: (params) => request.read('/olympiad-step/find-all', params, true),
  findOne: (params) => request.read('/olympiad-step/find-one', params, true),
  getNearest: (params) => request.read('/olympiad-step/nearest', params, true),
  getOne: (id) => request.read(`/olympiad-step/${id}`, null, true),
  getParticipantRegistrationOpened: (params) => request.read('/olympiad-step/participant-registration-opened', params, true),
  getScoreCriteria: (id) => request.read(`/olympiad-step/${id}/score-criterion`, null, true),
  getStatisticsParticipantClass: (id) => request.read(`/olympiad-step/${id}/statistics/participant/class`, null, true),
  getStatus: (id) => request.read(`/olympiad-step/${id}/status`, null, true),
  /**
   * Получает критерии оценивания для данного этапа и площадки.
   * @param olympiadStepId
   * @param locationId
   * @returns {Promise<boolean|*|undefined>}
   * @deprecated Метода на замену пока нет. Нужно реализовать получение критериев оценивания через соответствующий контроллер.
   */
  getLocationPage: (olympiadStepId, locationId) => request.read(`/olympiad-step/${olympiadStepId}/location-page/${locationId}`, null, true),
  /**
   * TODO: переделать на /olympiad-step/${olympiadStepId}/status?${params}
   */
  getStatusForLocation: (olympiadStepId, locationId) => request.read(`/olympiad-step/${olympiadStepId}/${locationId}/status`, null, true),
  postScoreCriterion: (id, data) => request.create(`/olympiad-step/${id}/score-criterion`, data, true),
  putOne: (id, data) => request.update(`/olympiad-step/${id}`, data, true),
  putSetting: (id, data) => request.update(`/olympiad-step/${id}/setting`, data, true),
};

export {olympiadStep};