import {useLocation} from "react-router-dom";
import Item from "./Item";
import {itemIsActive, ROOT_PATH} from "../../utils/itemIsActive";

export default function ProfileItem(props) {
  const {link = ROOT_PATH, activeFor = ['/profile', '/oauth', ROOT_PATH]} = props;
  const url = useLocation();

  return (
    <Item
      link={link}
      label={'Мой профиль'}
      active={itemIsActive(url.pathname, activeFor)}
    />
  );
}