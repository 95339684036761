import SectionsItem from "./items/SectionsItem";
import AnnouncementsItem from "./items/AnnouncementsItem";
import MessagesItem from "./items/MessagesItem";
import ProfileItem from "./items/ProfileItem";
import {ROOT_PATH} from "../utils/itemIsActive";

export default function ParticipantItems() {
  return (
    <>
      <SectionsItem label={'Мои предметы'} activeFor={['/sections', '/section', '/locations']}/>
      <AnnouncementsItem/>
      <MessagesItem/>
      <ProfileItem link={'/profile'} activeFor={['/profile', '/history', '/oauth', ROOT_PATH]}/>
    </>
  );
}