import LocationsItem from "./items/LocationsItem";
import ParticipantsItem from "./items/ParticipantsItem";
import AnnouncementsItem from "./items/AnnouncementsItem";
import MessagesItem from "./items/MessagesItem";
import ProfileItem from "./items/ProfileItem";

export default function ManagerItems() {
  return (
    <>
      <LocationsItem label={'Мои площадки'} activeFor={['/locations', '/location', '/report']}/>
      <ParticipantsItem/>
      <AnnouncementsItem/>
      <MessagesItem/>
      <ProfileItem/>
    </>
  );
}