import {useLocation} from "react-router-dom";
import Item from "./Item";
import {itemIsActive} from "../../utils/itemIsActive";

export default function SolutionsItem() {
  const url = useLocation();

  return (
    <Item
      link={'/solutions'}
      label={'Решения'}
      active={itemIsActive(url.pathname, ['/solutions'])}
    />
  );
}