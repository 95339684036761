import {request} from "../request/request";

const user = {
  checkProfile: () => request.read('/user/check-profile', null, true),
  findAll: (params) => request.read('/user/find-all', params, true),
  findOne: (params) => request.read('/user/find-one', params, true),
  getJuriesAssigned: (params) => request.read('/user/jury-assigned', params, true),
  getJuriesAvailable: (params) => request.read('/user/jury-available', params, true),
  getOne: (userId) => request.read(`/user/${userId}`, null, true),
  getVerify: (userId) => request.read(`/user/${userId}/verify`, null, true),
  postJury: (data) => request.create('/user/jury', data, true),
  postModerator: (data) => request.create('/user/moderator', data, true),
  putJuryBind: (userId, data) => request.update(`/user/${userId}/jury-bind`, data, true),
  putJuryUnbind: (userId, data) => request.update(`/user/${userId}/jury-unbind`, data, true),
  putModeratorBind: (userId, data) => request.update(`/user/${userId}/moderator-bind`, data, true),
  putVerify: (userId, data) => request.update(`/user/${userId}/verify`, data, true),
  putVerifyInvited: () => request.update('/user/verify-invited', null, true),
};

export {user};