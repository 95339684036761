import Item from "./Item";
import {itemIsActive} from "../../utils/itemIsActive";
import {useLocation} from "react-router-dom";

export default function ArchiveItem() {
  const url = useLocation();

  return (
    <Item
      link={'/archive'}
      label={'Архив'}
      active={itemIsActive(url.pathname, ['/archive'])}
    />
  );
}