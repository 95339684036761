import {request} from "../request/request";

const material = {
  findAll: (params) => request.read('/material', params, true),
  findOne: (params) => request.read('/material/find', params, true),
  getMaxTasks: (params) => request.read('/material/max-task', params, true),
  getOne: (materialId) => request.read(`/material/${materialId}`, null, true),
  postOne: (data) => request.create('/material', data, true),
  putOne: (materialId, data) => request.update(`/material/${materialId}`, data, true),
};

export {material};