import BlockHeader from "../header/BlockHeader";
import TextBlock from "../text/TextBlock";
import {useDispatch} from "react-redux";
import {closeFlashMessage} from "../../storage/redux/slices/flashMessages";

export default function FlashMessage(props) {
  const dispatch = useDispatch();
  const {index, title, message, type = 'info'} = props;
  const text = [];

  prepareText(message);

  return (
    <div className={`flash-message flash-message-${type}`}>
      <div className={'flash-message-btn-close'} onClick={handleClose}>Закрыть</div>
      {renderTitle(title)}
      {renderText(text)}
    </div>
  );

  function prepareText(message) {
    if (undefined === message) {
      return;
    }

    if (typeof message === "string" || message instanceof String) {
      text.push(message);
    }

    if (message instanceof Object && message.constructor === Object) {
      for (const item in message) {
        prepareText(message[item]);
      }
    }

    if (message instanceof Array && message.constructor === Array) {
      message.forEach((item) => {
        prepareText(item);
      });
    }
  }

  function renderTitle(content) {
    if (null === content || [] === content || !content) return;
    return <BlockHeader title={content}/>;
  }

  function renderText(content) {
    if (null === content || [] === content || !content) return;
    return <TextBlock text={content}/>;
  }

  function handleClose(event) {
    event.preventDefault();
    dispatch(closeFlashMessage(index));
  }
}