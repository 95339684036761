import React, {Suspense} from "react";
import {useSelector} from "react-redux";
import Loader from "../../components/loader/Loader";

const RootRouter = React.lazy(() => import('../roles/root/content/RootRouter'));
const AdminRouter = React.lazy(() => import('../roles/admin/content/AdminRouter'));
const ModeratorRouter = React.lazy(() => import('../roles/moderator/content/ModeratorRouter'));
const JuryRouter = React.lazy(() => import('../roles/jury/content/JuryRouter'));
const ManagerRouter = React.lazy(() => import('../roles/manager/content/ManagerRouter'));
const ParticipantRouter = React.lazy(() => import('../roles/participant/content/ParticipantRouter'));
const GuestRouter = React.lazy(() => import('../roles/guest/content/GuestRouter'));

export default function Content() {
  const user = useSelector(state => state.user);

  return (
    <div className={user.isGuest ? 'content-guest' : 'content-auth'}>
      <Suspense fallback={<Loader/>}>
        {renderRouter()}
      </Suspense>
    </div>
  );

  function renderRouter() {
    if (null === user.profile) {
      return <GuestRouter/>;
    }

    const roleName = user.profile.role.name;

    if ('root' === roleName) {
      return <RootRouter/>;
    } else if ('admin' === roleName) {
      return <AdminRouter/>;
    } else if ('moderator' === roleName) {
      return <ModeratorRouter/>;
    } else if ('jury' === roleName) {
      return <JuryRouter/>;
    } else if ('manager' === roleName) {
      return <ManagerRouter/>;
    } else if ('participant' === roleName) {
      return <ParticipantRouter/>;
    }

    return <GuestRouter/>;
  }
}
