import {request} from "../request/request";

const variant = {
  add: (data) => request.create('/variant', data, true),
  deleteOne: (variantId) => request.remove(`/variant/${variantId}`, null, true),
  findAll: (params) => request.read('/variant', params, true),
  findOne: (params) => request.read('/variant/find', params, true),
  getOne: (variantId) => request.read(`/variant/${variantId}`, null, true),
};

export {variant};