import "../../assets/item.css";
import {Link} from "react-router-dom";

export default function Item(props) {
  const {link, label, count = null, active = false} = props;

  return (
    <Link to={link} className={getCssClasses()}>
      <span className={'main-menu-item__label'}>{label}</span>
      {renderCount()}
    </Link>
  );

  function renderCount() {
    if (undefined === count || null === count || 0 === count) {
      return null;
    }

    return <span className={'main-menu-item__count'}>{count}</span>;
  }

  function getCssClasses() {
    const cssClasses = ['nav-link main-menu-item'];

    if (active) {
      cssClasses.push('main-menu-item_active');
    }

    return cssClasses.join(' ');
  }
}