export default class TextHelper {
  static ltrim(text) {
    if (null === text) return '';
    return text.replace(/^[\s]+/, '');
  }

  static rtrim(text) {
    if (null === text) return '';
    return text.replace(/[\s]+$/, '');
  }

  static trim(text) {
    if (null === text) return '';
    return this.ltrim(this.rtrim(text));
  }

  static splitLines(text) {
    return this.trim(text).split('\n');
  }
}
