import "../assets/menu.css";
import {Nav, Navbar} from "react-bootstrap";
import Logo from "../../../../components/logo/Logo";
import {useSelector} from "react-redux";
import {ROOT_PATH} from "../utils/itemIsActive";
import AdminItems from "./AdminItems";
import ModeratorItems from "./ModeratorItems";
import JuryItems from "./JuryItems";
import ManagerItems from "./ManagerItems";
import ParticipantItems from "./ParticipantItems";

export default function Menu() {
  const isGuest = useSelector(state => state.user.isGuest);
  const profile = useSelector(state => state.user.profile);

  return (
    <Navbar expand={'lg'} className={'main-menu'}>
      <Navbar.Brand as={'div'}>
        <Logo/>
      </Navbar.Brand>
      {renderItemsBlock()}
    </Navbar>
  );

  function renderItemsBlock() {
    if (isGuest) {
      return null;
    }

    return (
      <>
        <Navbar.Toggle aria-controls={'basic-navbar-nav'}/>
        <Navbar.Collapse id={'basic-navbar-nav'}>
          <Nav variant={'pills'} defaultActiveKey={ROOT_PATH} className={'main-menu__items ml-auto'}>
            {renderItemsByRole()}
          </Nav>
        </Navbar.Collapse>
      </>
    );
  }

  function renderItemsByRole() {
    if (['root', 'admin'].includes(profile.role.name)) {
      return <AdminItems/>;
    } else if ('moderator' === profile.role.name) {
      return <ModeratorItems/>;
    } else if ('jury' === profile.role.name) {
      return <JuryItems/>;
    } else if ('manager' === profile.role.name) {
      return <ManagerItems/>;
    } else if ('participant' === profile.role.name) {
      return <ParticipantItems/>;
    }

    return [];
  }
}
