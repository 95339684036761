import "./controls-group.css";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";

export default function ControlsGroup(props) {
  const {editLink = null, deleteLink = null, variant = null} = props;

  return (
    <div className="controls-group">
      {renderEditButton()}
      {renderDeleteButton()}
    </div>
  );

  function renderEditButton() {
    if (null === editLink) return null;
    return <EditButton link={editLink} variant={variant} />;
  }

  function renderDeleteButton() {
    if (null === deleteLink) return null;
    return <DeleteButton link={deleteLink} variant={variant} />;
  }
}
