import iconBlue from "../icon/icon-edit.svg";
import iconWhite from "../icon/icon-edit-white.svg";
import ControlButton from "./ControlButton";

export default function EditButton(props) {
  const {link, variant = 'primary'} = props;

  return <ControlButton link={link} icon={renderIcon()} alt={'Редактировать'}/>;

  function renderIcon() {
    if ('primary' === variant) {
      return iconBlue;
    } else if ('secondary' === variant) {
      return iconWhite;
    }

    return iconBlue;
  }
}
