import {saveAs} from 'file-saver';
import {request} from "../request/request";

const file = {
  getOne: async (hash) => {
    const response = await request.read(`/file/${hash}`, null, true);
    saveAs(response.url, response.name);
  },
};

export {file};