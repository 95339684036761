import {api} from "../api";
import {token} from "../token/token";

const signOutLocal = () => {
  token.removeTokens();
};

const signOutLocalLegacy = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

const signOut = async () => {
  try {
    await api.oauth.signOut.create();
    signOutLocal();
    signOutLocalLegacy();
  } catch (error) {
    throw error;
  }
};

export {signOutLocal, signOutLocalLegacy, signOut};