import {request} from "../request/request";

const region = {
  findAll: (params) => request.read('/region', params, true),
  findOne: (params) => request.read('/region/find', params, true),
  getOne: (regionId) => request.read(`/region/${regionId}`, null, true),
  getPermitted: () => request.read('/region/permitted', null, true),
  getTopParticipants: (params) => request.read('/region/top-participant', params, true),
};

export {region};