import {useLocation} from "react-router-dom";
import Item from "./Item";
import {itemIsActive} from "../../utils/itemIsActive";

export default function ParticipantsItem() {
  const url = useLocation();

  return (
    <Item
      link={'/participants'}
      label={'Участники'}
      active={itemIsActive(url.pathname, ['/participants', '/participant'])}
    />
  );
}