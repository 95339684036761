import {request} from "../request/request";

const studyClass = {
  findAll: (params) => request.read('/study-class', params, true),
  findOne: (params) => request.read('/study-class/find', params, true),
  getOlympiad: () => request.read('/study-class/olympiad', null, true),
  getOne: (id) => request.read(`/study-class/${id}`, null, true),
  getParticipantEnrolment: (params) => request.read('/study-class/participant-enrolment', params, true),
};

export {studyClass};