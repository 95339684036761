import React, {Suspense} from "react";
import {useSelector} from "react-redux";
import Sticky from "wil-react-sticky";
import Loader from "../../components/loader/Loader";

const RootRouter = React.lazy(() => import('../roles/root/action/RootRouter'));
const AdminRouter = React.lazy(() => import('../roles/admin/action/AdminRouter'));
const ModeratorRouter = React.lazy(() => import('../roles/moderator/action/ModeratorRouter'));
const JuryRouter = React.lazy(() => import('../roles/jury/action/JuryRouter'));
const ManagerRouter = React.lazy(() => import('../roles/manager/action/ManagerRouter'));
const ParticipantRouter = React.lazy(() => import('../roles/participant/action/ParticipantRouter'));
const GuestRouter = React.lazy(() => import('../roles/guest/action/GuestRouter'));

export default function Actions() {
  const user = useSelector(state => state.user);

  return (
    <div className={user.isGuest ? 'actions-guest' : 'actions-auth'}>
      <Sticky containerSelectorFocus={'actions-side'}>
        <Suspense fallback={<Loader/>}>
          {renderRouter()}
        </Suspense>
      </Sticky>
    </div>
  );

  function renderRouter() {
    if (null === user.profile) {
      return <GuestRouter/>;
    }

    const roleName = user.profile.role.name;

    if ('root' === roleName) {
      return <RootRouter/>;
    } else if ('admin' === roleName) {
      return <AdminRouter/>;
    } else if ('moderator' === roleName) {
      return <ModeratorRouter/>;
    } else if ('jury' === roleName) {
      return <JuryRouter/>;
    } else if ('manager' === roleName) {
      return <ManagerRouter/>;
    } else if ('participant' === roleName) {
      return <ParticipantRouter/>;
    }

    return <GuestRouter/>;
  }
}
